import React, { Component } from 'react';
import SocialMedia from '../common/SocialMedia';


class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                {/* { this.props.isMobile ?  */}
                        <div className='footer' style={{textAlign:'center'}}>
                            <SocialMedia isMobile={this.props.isMobile}/>
                        </div>
                            
                        
                        
                {/* : null }  */}
            </React.Fragment>
        )
    }
}
export default Footer;