import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';
// import About from './components/About';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Sponsors from './components/Sponsors';

//import axios from 'axios';
import './styles.css';

var CheckIfMobile = function(){
  if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    return true;
  }else{
    return false;
  }
}

class App extends Component {

  state = {
    isMobile: false,
    scheduleData: []
  }
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let isMobile = CheckIfMobile();
    if(isMobile){
      this.setState({ isMobile : true });
    }
    else{
      this.setState({ isMobile : false });
    }
 }

  render(){
    return (
      <Router>
          <Route exact path="/" render={props =>(
            <React.Fragment>
              <Home isMobile={this.state.isMobile} />
            </React.Fragment>
          )}/>
      
      <Route path="/sponsors" render={props =>(
              <React.Fragment >
                  <Sponsors isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>

        <Route path="/gallery" render={props =>(
              <React.Fragment >
                  <Gallery isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>
        <Route path="/contact" render={props =>( 
              <React.Fragment >
                  <Contact isMobile={this.state.isMobile}/>>
              </React.Fragment>
              )}/>

      </Router>

    );
  }
}

export default App;
