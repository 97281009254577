import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import LogoBU from '../content/LogoBU.png';

class Sponsors extends Component {
    render() {
        return (
            <React.Fragment>
                <Header  isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont" style={{color:'white',textAlign:'left',fontSize:'80px'}}>sponsors</div>
                        <hr className="AccentColor" style={{marginTop:'-25px'}} />
                    </div>

                    <a href='https://www.justbu360.com/' target='_blank'><img src={LogoBU} alt="" style={{width:'240px'}} /></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'240px',textAlign:'left'}}>
                    We encourage others to be themselves. We are all unique in our own way, JUST BE YOU all year round 365 days or 360°. The choice is yours! 
                    </div>
                    <a href='https://www.justbu360.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='mailto:justbu360@gmail.com' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    <a href='https://twitter.com/justbu360' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    <a href='https://www.facebook.com/justbu360' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/justbu360/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a>
                    
                    {/* 
                    <br/><br/>
                    <br/><br/>
                    <a href='https://ScoringSenate.com/'  target='_blank'><img src={CombatComboverBanner} alt="" style={{width:'300px'}}/></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                        Scoring Senate is a real-time, experts-based, & large-group scoring platform for combat sports. Don't let it go to the judges.
                    </div>
                    <a href='https://ScoringSenate.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='mailto:info@ScoringSenate.com' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    <a href='https://twitter.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    <a href='https://www.facebook.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a> */}

                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </center>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Sponsors;