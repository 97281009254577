import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import willis02 from '../content/willis02.png';
import willis03 from '../content/willis03.png';
import willis04 from '../content/willis04.png';
import willis05 from '../content/willis05.png';
import willis06 from '../content/willis06.png';
import willis02thumb from '../content/willis02thumb.png';
import willis03thumb from '../content/willis03thumb.png';
import willis04thumb from '../content/willis04thumb.png';
import willis05thumb from '../content/willis05thumb.png';
import willis06thumb from '../content/willis06thumb.png';


class Gallery extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
        const images = [
             { original: willis02, thumbnail: willis02thumb },
             { original: willis03, thumbnail: willis03thumb },
             { original: willis04, thumbnail: willis04thumb },
             { original: willis05, thumbnail: willis05thumb },
             { original: willis06, thumbnail: willis06thumb }
           ]
      

        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel7" style={{color:'white',textAlign:'left'}}>gallery</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />
                    </div>

                    <div style={{padding:'0px 50px 50px 50px',maxWidth:'800px',textAlign:'center'}}>
                        <ImageGallery items={images} showThumbnails={false} />
                    </div>
                </center>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Gallery;