import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import interview from '../content/willis01.png';
import LogoBU from '../content/LogoBU.png';

class Home extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }
    
    render() {
        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div className='ThemeFont FontSizeLevel6' 
                    style={{color:'white',textAlign:'center'}}>
                        WILLIS <span style={{color:'gray'}}>SILVERBACK</span> BLACK</div>                            
                    <br/>
                    <br/>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td style={{textAlign:'right'}}>
                                {/* <SocialMedia isMobile={this.props.isMobile}/> */}
                            </td>   
                        </tr>
                        <tr>
                        <td>
                            <div>
                                <img className="ImageHero" src={interview} alt="" />
                                <center className='MobileOnly'>
                                    <div className='ThemeFont FontSizeLevel2' style={{color:'white',width:'320px',padding:'20px',textAlign:'center',backgroundColor:'rgba(0,0,0,0.75)', marginTop:'-20px'}}>
                                    <br />
                                    It’s gonna take time, so take your time, because you have it. #silverbackmma #willisblackmma life long #mma #NODECISIONS 7-3 pro #wxcmma #Champ</div>                            
                                </center>
                            <br />
                            </div>
                        </td>
                        <td valign='top'>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='ThemeFont DesktopOnly FontSizeLevel2' style={{color:'white',width:'300px',padding:'30px',textAlign:'left',backgroundColor:'rgba(0,0,0,0.75)'}}>
                                It’s gonna take time, so take your time, because you have it. #silverbackmma #willisblackmma life long #mma #NODECISIONS 7-3 pro #wxcmma #Champ
                                </div>                            
                            <br />

                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                
                <div className={'SubBodyText FontSizeLevel1'} style={{color:'white',marginLeft:'30px'}}> S P O N S O R S </div>
                <br/>
                <div style={{width:'300px'}}>
                    <table>
                        <tr>
                            <td valign='top'>
                                <a href='https://justbu360.com/' target='_blank'><img src={LogoBU} alt="" style={{height:'75px',marginRight:'15px'}} /></a>
                            </td>
                            <td>
                                <div className={'SubBodyText FontSizeLevel1'}>
                                We encourage others to be themselves. We are all unique in our own way, JUST BE YOU all year round 365 days or 360°. The choice is yours! 
                                </div>
                                <div style={{textAlign:'right'}}>
                                    <a href='https://twitter.com/justbu360' target='_blank'>
                                        <div className="ThemeFont btn FontSizeLevel1"><i className='fa fa-twitter'></i></div>
                                    </a>
                                    <a href='https://www.facebook.com/justbu360' target='_blank'>
                                        <div className="ThemeFont btn FontSizeLevel1"><i className='fa fa-facebook'></i></div>
                                    </a>
                                    <a href='https://www.instagram.com/justbu360/' target='_blank'>
                                        <div className="ThemeFont btn FontSizeLevel1"><i className='fa fa-instagram'></i></div>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <br />

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </center>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Home;