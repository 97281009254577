import React, { Component } from 'react';

class SocialMedia extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
        return (
            <React.Fragment>
                {/* { !this.props.isMobile ?  */}
                        <div>
                            <a href='https://www.youtube.com/watch?v=KiaSeYsA0nI&list=PLra24N2a2c1tMInnPO0Jx0XnohMcknRxh' target='_blank' rel="noopener noreferrer"><div className="fa fa-youtube-play" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <a href='https://twitter.com/SilverbackBlack' target='_blank' rel="noopener noreferrer"><div className="fa fa-twitter" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <a href='https://www.instagram.com/silverbackblack' target='_blank' rel="noopener noreferrer"><div className="fa fa-instagram" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <a href='https://www.facebook.com/silverbackblack' target='_blank' rel="noopener noreferrer"><div className="fa fa-facebook" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                {/* : null } */}
            </React.Fragment>
        )
    }
}
export default SocialMedia;